import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";

const CoachForEntrepreneursPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "11.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mike: file(relativePath: { eq: "testimonials/Mike-Sherwood.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				nick: file(relativePath: { eq: "testimonials/nick-bryant.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				sarah: file(relativePath: { eq: "testimonials/sarah-bennett.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Coach for Entrepreneurs";
	const description = "";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const mike = data.mike.childImageSharp.gatsbyImageData;
	const sarah = data.sarah.childImageSharp.gatsbyImageData;
	const nick = data.nick.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Coach for Entrepreneurs",
				item: {
					url: `${siteUrl}/coach-for-entrepreneurs`,
					id: `${siteUrl}/coach-for-entrepreneurs`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/coach-for-entrepreneurs`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Coach for Entrepreneurs",
						},
					],
				}}
			/>
			<Hero
				isDarken={true}
				backgroundImage={heroImage}
				title="Coach for Entrepreneurs London"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<p>
								Choosing to set up your own business is a big decision and one
								that can pay off exponentially, putting you in control of your
								own destiny. However owning and running a small business
								involves many challenges and daily up’s and down’s.{" "}
							</p>
							<p>
								Not only do you have to manage every ‘department’ or role in the
								business and look after the clients or customers at the same
								time, but you also need to maintain a work life balance too. How
								can you achieve these seemingly lofty goals?
							</p>
							<p>
								The answer. Hire a entrepreneur coach who has been there, seen
								it, done it over many years, to support you as your ‘hidden
								business partner’ through the process.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								How can a Entrepreneur coach help you?
							</h3>
							<p>
								Running a successful business is more than time management and
								planning your day to day routine. It's about setting a long term
								business vision and shorter term goals, finding the right ‘how
								do I get from A to B?’ and building a team to increase revenue
								and provide an above average service to ‘solve the problems’ of
								your clients.{" "}
							</p>
							<p>
								With a business career spanning over 35 years, I've worked with
								hundreds of entrepreneurs who are feeling stuck. This stuckness
								might be about lack of focus or indeed any plan at all, feelings
								of burnout or overwhelm, needing to improve business skills,
								wanting a better work life balance, how to grow or scale the
								business, reach and land more clients or simply the need for an
								experienced sounding board. I've seen so many times how my
								support over time turns things around for entrepreneurs and
								their small businesses.{" "}
							</p>
							<p>
								From Architects, Accountants and Solicitors to Consultants,
								Teachers, Trainers and Coaches of every type to Actors, Artists,
								Dog groomers, Photographers, Web designers, Builders and Garden
								landscapers. You name a business type and the chances are that I
								have worked with them or similar. With your commitment to my
								bespoke entrepreneur coaching program, you can completely change
								your future.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								Where do you see yourself in five years?
							</h3>

							<p>
								If you could create an ideal circumstance for you and your small
								business in five years, what would it look like?
							</p>
							<ul className="pl-4">
								<li>
									A day to day role designed to maximise your strengths and
									talents?{" "}
								</li>
								<li>How much ££££ income would you take from the business?</li>
								<li>
									How many employees, freelancers or sub-contractors would you
									have?
								</li>
								<li>What work-life balance would make you happy?</li>
								<li>Would you hire a manager and take a step back?</li>
								<li>
									What exciting personal or business goals would you have
									achieved?
								</li>
							</ul>
							<p>
								Visualising your dream is the first step to turning it into a
								reality!
							</p>
							<p>
								So let me ask you, what’s holding you back? What are your
								challenges?
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="mb-md-5 mb-4">
				<Container>
					<Row>
						<Col>
							{" "}
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/lHA14JRHFIA"
									title="Testimonial | Jason Cornes"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">
								What entrepreneur coaching will do for you:
							</h2>
							<ul className="pl-4">
								<li>Are you tired of making decisions by yourself?</li>
								<li>Do you need someone to bounce ideas off?</li>
								<li>Has your business growth slowed?</li>
								<li>
									Do you need to become a better business manager or leader?
								</li>
								<li>
									Do you lack the time, energy or inspiration to develop a plan?
								</li>
								<li>Is your time management impacting your personal life?</li>
								<li>
									Are you struggling with a lack of clear targets or goals?
								</li>
								<li>Are you feeling stuck?</li>
							</ul>
							<p>
								These are all common problems experienced by entrepreneurs. The
								reality is that you must overcome them one way or another to be
								successful.
							</p>
							<p>
								This is where my online entrepreneur coaching will help you.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								How my entrepreneur coaching services work
							</h2>
							<p>
								My entrepreneur coaching programs are designed around the needs
								of you and your business. I offer a tailored approach, working
								in any business type. From professions such as Architects,
								Solicitors and Accountants to all types of health and wellness
								services, e-commerce and digital, creatives and trades of all
								shapes and sizes.{" "}
							</p>
							<p>
								As one of my entrepreneur coaching clients, I will make it my
								priority to understand you and your business, help you to create
								a vision of what you REALLY want and then together we’ll make it
								happen step by step over time. I will work as your strategic
								coach and as a sounding board for ideas and all the decisions
								which will need to be made to ‘get you there’.{" "}
							</p>

							<p>
								Unlike some business coaches who offer a pre-prepared coaching
								process, my signature program is designed from the ground up
								around you, your circumstances and what you need and want. This
								ensures I understand the ins and outs of your business, helping
								me build on your strengths and support you to make improvements.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/1.jpg"
							/>
							<h2 className="text-primary pb-3">
								Challenging you to achieve more
							</h2>

							<p>
								Goal achievement is a concept that's loosely thrown around by
								self-employed entrepreneurs. Everyone has goals and yet so few
								people achieve them. Why? The biggest problem is accountability.
								Typically entrepreneurs are independent creatures who are used
								to working alone. They only have themselves to hold accountable.
								As a result, when things get busy or life becomes too much their
								standards will slip. Things like procrastination or imposter
								syndrome kick in. By having one of the most experienced business
								coaches by your side, you will always be held accountable for
								your actions. You’ll get more done!
							</p>
							<p>
								I help entrepreneurs by empowering them. I challenge traditional
								thought processes, false beliefs and assumptions. I help
								entrepreneurs and small business owners see problems and
								opportunities from a new perspective. They see new ways of doing
								things, take calculated risks and stop ‘doing things in the same
								way and expecting different results’ Worst case being a ‘busy
								fool’ in other words lots of activity but much of it
								ineffective, unprofitable and not taking them where they want to
								be going.
							</p>
							<p>
								It's not an easy process but with commitment from both of us and
								a step by step approach, one that pays off.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">
								Why should you listen to this entrepreneur coach?
							</h2>
							<p>
								There are a lot of business coaches out there, many talk well
								and make bold claims. So what makes me different? Firstly, I've
								been in your shoes. As someone who’s been self-employed my whole
								life, I've been through the highs and lows of business. I know
								what it feels like. As a business owner since the 1980’s and
								business coach since 2017, I've worked with business owners from
								a wide range of business types and circumstances, coaching each
								to achieve their goals in a cost effective way.
							</p>
							<p>
								Instead of providing you with generic ‘tick box’ business coach
								advice, I work as a strategic coach, agreeing with you each and
								every session, actionable tasks that will help get you to where
								you want to be. Holding you accountable each step of the way.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								What my clients say about me
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Mike Sherwood. FreshOnline Ltd"
						review="I have been working with Jason Cornes for the last 5 months and have seen amazing results. Jason has been able to understand my business and identify opportunities to improve what we already do and find new ways to grow and expand. Since working with Jason we have employed another full time staff member, added new services and increased profitability on our existing work. I feel so much more in control and confident about the future. I have no hesitation recommending Jason and I look forward to continuing to use his business coaching services long term."
						linkedIn="https://www.linkedin.com/in/mikesherwood147"
						profilePic={mike}
					/>

					<HorizontalTestimonial
						name="Nick Bryant – Brilliant Businesses"
						review="I recently used Jason to help me with driving my business forward. From the initial meeting I knew he would help me, he approached things in a way that suited me and helped make me see things in a different way, which steered me towards a stronger business model. Jason is very easy to get along with and really listens, he's the best business coach I've used and I recommend that you use him too."
						showLinkedIn="d-none"
						profilePic={nick}
					/>
					<HorizontalTestimonial
						name="Sarah Bennett Commercial Photography."
						review="I’ve worked with Jason for two years this month. When I came to Jason for help, I wasn’t entirely sure what a business coach did, I thought maybe a few sessions then that was it..2 years on and I can truly say my business has gone from strength to strength, but I still need him. Jason is the person I tell all my ideas to, and bounce them off. He’s the one person that will give me an honest answer and opinion, he tells me what I need to hear, not necessarily what I want to hear. Because he’s always run his own businesses, he knows his stuff, it’s based on training yes, but more importantly I fell on his 30 years of experience in running his own companies. His ideas are inspiring and I can’t recommend him enough to others."
						linkedIn="https://www.linkedin.com/in/sarahbennettcommercial/"
						profilePic={sarah}
					/>
				</Container>
			</section>
			<section className="pt-5 pb-4">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Make business development your top priority
							</h3>
							<p>
								In the fast paced modern environment it's easy to find yourself
								feeling stuck, overwhelmed or lacking focus. Many of the
								business owners I speak to believe they don't have the time to
								work on themselves. As an experienced business coach, I can tell
								you this mindset is holding you back. Slowing down is usually
								more effective than going faster and faster which can only lead
								to stress and burnout.
							</p>
							<p>
								It's all about your priorities. Investing in yourself is an
								upfront tax deductible cost that will lead to more money, more
								time and greater success as a business owner. As your strategic
								coach I will make sure you have a clear vision of where you want
								to take your business, the steps to get there and the actions
								needed.
							</p>
							<p>
								With the support of your experienced business coach money,
								happiness and success are coming. It's only a matter of time.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Book a call today</h3>
							<p>
								If you're looking for a business coach that will help you grow
								your business and yourself as an entrepreneur, look no further.
								I'm your guy.{" "}
							</p>
							<p>
								My coaching will help you develop your business, improve your
								mindset and ensure you can deliver better results for your
								clients.
							</p>
							<p>
								Send me a message now and discover how my services can help you
								take your business to the next level.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default CoachForEntrepreneursPage;
