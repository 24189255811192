import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { FaLinkedin } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import { Row } from "react-bootstrap";

const HorizontalTestimonial = ({
	name,
	review,
	linkedIn,
	profilePic,
	showLinkedIn,
}) => {
	const linkedInVisible = showLinkedIn || "d-block";

	return (
		<Row className="py-5 align-items-xl-center">
			<Col
				xs={12}
				xl={4}
				className="px-6 px-md-10 px-xl-5 text-center testimonial-image"
			>
				<GatsbyImage image={profilePic} style={{ borderRadius: "100%" }} />
			</Col>
			<Col xs={12} xl={8}>
				<h4 className="text-tertiary text-center pt-4">{name}</h4>
				<p className="text-center pl-auto pr-auto ">
					<a
						rel="noreferrer"
						href={linkedIn}
						target="_blank"
						className={`linkedin-icon ${linkedInVisible}`}
					>
						<FaLinkedin />
					</a>
				</p>
				<p>
					<span className="text-primary">"</span>
					{review}
					<span className="text-primary">"</span>
				</p>
			</Col>
		</Row>
	);
};

export default HorizontalTestimonial;
